#easyduct-home,
#upgrade-page,
#advertise-page,
#invest-page {
  .boxed-container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }

  .main-content {
    @apply max-w-[1200px] flex-grow;
  }

  .main-content-container {
    @apply flex-grow flex gap-10 p-5 lg:p-10 justify-center;
  }

  .gradient-header {
    @apply text-6xl leading-normal font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#8DF924] to-[#00B4FF];
  }

  .bg-full-bleed-green {
    box-shadow: 0 0 0 100vmax #dcfff8;
    clip-path: inset(0 -100vmax);
    background: #dcfff8;
  }

  .bg-full-bleed-gray {
    box-shadow: 0 0 0 100vmax #e3e7ee;
    clip-path: inset(0 -100vmax);
    background: #e3e7ee;
  }

  .bg-full-bleed-dark-green {
    box-shadow: 0 0 0 100vmax #cbffff;
    clip-path: inset(0 -100vmax);
    background: #cbffff;
  }
}

#advertise-box {
  @apply bg-white rounded-xl border border-gray-500 px-10 py-20 shadow-md;

  .box-header {
    @apply flex flex-col gap-2 text-5xl font-extrabold text-[#282D35];
  }

  .benefit-icon {
    @apply flex items-center justify-center px-7 py-6 max-w-max rounded-[2rem] hover:rotate-12 duration-200 hover:scale-110;
  }
}

.home-sample-ads {
  @apply min-w-[200px] min-h-[250px] p-7;

  .ad-icon {
    @apply px-2 py-1 rounded-md  bg-[#282D35] font-bold;
  }

  .ad-title {
    @apply text-xl font-black text-[#282D35];
  }

  .ad-price {
    @apply text-6xl font-extrabold text-[#282D35] mt-5 mb-3;
  }

  .ad-desc {
    @apply bg-[#282D35] text-white py-1 px-3 font-semibold max-w-max rounded-md;
  }
}

#home-contact-form {
  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    @apply px-5 py-2 border border-gray-400 focus-visible:outline-[#000] rounded-xl w-full;
  }

  .submit-btn {
    @apply flex gap-3 items-center justify-center bg-[#282D35] text-white px-10 py-4 font-semibold rounded-xl cursor-pointer hover:bg-gray-600;
  }
}

#invest-box {
  @apply bg-white rounded-xl border border-gray-500 px-10 py-16 shadow-md;

  .box-header {
    @apply flex flex-col gap-2 text-5xl font-extrabold text-[#282D35];
  }
}

.sign-up-btn {
  @apply bg-[#282d35] text-white font-semibold py-4 px-8 rounded-xl hover:opacity-90;
}

#upgrade-plan-box {
  @apply relative flex flex-col px-8 pt-8 pb-10 bg-white border rounded-lg hover:border-gray-700 shadow-lg transition-[height] duration-300;

  .recommended {
    @apply w-[80%] top-[-20px] flex items-center gap-2 bg-[#282D35] justify-center py-2 rounded-lg absolute text-[#FFCC00] font-semibold;
  }

  .title {
    @apply mt-3 text-[2.7rem] font-extrabold leading-none;
  }

  .description {
    @apply text-sm font-semibold text-[#888B8F];
  }

  .price-box-wrapper {
    @apply bg-[#EBEBEB] rounded-xl mt-8 py-7;
  }

  .price {
    @apply text-[4rem] font-extrabold leading-none;
  }

  .plan-duration {
    @apply bg-[#282D35] text-white px-5 max-w-max py-1 rounded-xl;
  }

  .hidden-features {
    @apply transition-opacity duration-300 ease-in-out flex items-center gap-4 mb-4;
  }

  .show-more {
    @apply text-[#00B8F5] font-semibold text-left mt-5 transition-opacity duration-300;
  }

  .upgrade-btn {
    @apply px-10 py-3 border-2 border-gray-700 shadow-md cursor-pointer rounded-xl mt-7 font-semibold;
  }
}

// style input checkbox as button
.chk-to-btn {
  @apply select-none rounded-md bg-gray-100;
}

.chk-to-btn div {
  @apply text-center py-2 rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 cursor-pointer drop-shadow-md;
}

.chk-to-btn label input {
  @apply hidden;
}

.chk-to-btn input:checked + div {
  @apply bg-[#00E09E] text-white text-center;
}

.add-more-fitting-btn {
  @apply bg-[#282D35] text-sm text-white py-2 px-4 rounded-md flex gap-2 items-center cursor-pointer justify-center h-[42px];
}

// style input radio as button
.rd-to-btn {
  .rd-wrapper {
    @apply float-left mr-[5px] max-w-max h-[40px] relative;
  }

  input {
    @apply block absolute inset-0;
  }

  input[type="radio"] {
    @apply opacity-0 z-[100]  cursor-pointer;
  }

  input[type="radio"]:checked + label {
    @apply bg-[#00e09e] flex items-center justify-center text-white;
  }

  label {
    @apply border border-gray-300 z-20 max-w-max px-6 h-[40px] flex items-center justify-center rounded-md;
  }
}
