@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./sidebar.scss";
@import "./content.scss";
@import "./header.scss";
@import "./buttons.scss";
@import "react-responsive-modal/styles.css";
@import "./modal.scss";
@import "./input.scss";
@import "./new-layout.scss";
@import "./homepage.scss";
@import "./modules.scss";
@import "./expandable.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@layer utilities {
  .hover-nav {
    // .nav-icon {
    //   filter: invert(59%) sepia(73%) saturate(981%) hue-rotate(114deg)
    //     brightness(98%) contrast(102%);
    // }
    // .nav-label {
    //   @apply text-[#00E09E];
    // }
  }
}

a {
  @apply cursor-pointer;
}
