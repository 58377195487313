.content {
  @apply p-5 h-full;
}

ol li {
  list-style-type: decimal;
}

// Summary
.item-value {
  @apply text-gray-600;
  span:not(:last-child)::after {
    content: ", ";
  }
}
