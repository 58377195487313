input[type="checkbox"] {
  @apply mr-1 cursor-pointer w-[18px] h-[18px];
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="textarea"],
select {
  @apply rounded-md w-full border p-2 border-gray-400;
}

.code-login input[type="text"] {
  @apply border w-full mb-5 tracking-[3em] py-[1.5rem] pl-[8%] pr-[5%];
}
