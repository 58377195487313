.btn-primary {
  @apply bg-ed-green-selected text-white px-10 py-2 rounded-md border border-ed-green-selected;
}

.btn-primary-gray {
  @apply bg-gray-700 text-white px-4 py-2 rounded-md border border-gray-700 hover:border-gray-300;
}

.btn-default-gray {
  &:disabled {
    @apply bg-gray-100 text-gray-400 hover:bg-none hover:border-gray-200 border-gray-200 hover:text-gray-400;
  }
  @apply bg-ed-light-gray px-4 py-2 rounded-md hover:bg-gray-200 hover:text-white hover:bg-gray-700 border border-gray-300;
}

.btn-default {
  &:disabled {
    @apply bg-gray-100 text-gray-400 hover:bg-none hover:border-gray-200 border-gray-200 hover:text-gray-400;
  }
  @apply bg-ed-light-gray px-10 py-2 rounded-md hover:bg-ed-green-selected hover:text-white hover:border-ed-green-selected border border-gray-300;
}

.btn-danger {
  @apply bg-red-500 text-white px-4 py-2 rounded-md;
}

.badge-black {
  @apply rounded-full bg-black text-white px-[7px] py-[2px] mr-2 text-xs;
}

.btn {
  @apply px-5 py-2 rounded-md shadow cursor-pointer hover:bg-gray-800;
}

.btn-black {
  @apply bg-black text-white px-5 py-2 rounded-md;
}

.btn-dark-gray {
  @apply bg-[#282D35] text-white px-5 py-2 rounded-md;
}

.run-btn {
  @apply flex p-2 max-w-max rounded-md border border-gray-300 cursor-pointer min-w-[130px] text-center;
}

.run-btn.selected {
  @apply bg-[#282D35] text-white;
}

.dark-grey-btn {
  @apply cursor-pointer border border-gray-300 py-2 px-5 rounded-md  bg-[#282D35] text-white;
}

.module-setting-btn {
  @apply bg-[#282D35] flex items-center justify-center gap-3 text-white rounded-md h-[36px] w-[200px] cursor-pointer;
}

.next-btn,
.previous-btn {
  @apply bg-[#282D35] flex items-center justify-center gap-3 text-white rounded-md h-[36px] w-[120px] cursor-pointer;
}

.next-btn:disabled,
.previous-btn:disabled {
  @apply opacity-75;
}

.active-tab {
  @apply bg-ed-green-selected text-white;
}

.inactive-tab {
  @apply bg-gray-100;
}
