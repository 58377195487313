.react-responsive-modal-modal {
  @apply p-0 rounded-lg;
}
.react-responsive-modal-closeButton {
  @apply top-2 right-2;
}

.react-responsive-modal-modal {
  max-width: 95%;
  @apply rounded-md;
}

.canvas-modal {
  @apply w-full h-[95%];
}

.update-measurements-modal,
.default-details-modal {
  @apply w-[80%] md:w-[70%] lg:w-[60%] border border-gray-600 overflow-hidden;

  .measurement-input,
  .detail-input {
    @apply border border-gray-500 max-w-[200px];
  }

  .invalid-field {
    @apply border-[2px] border-red-500;
  }
}

.success-save-jobsite {
  @apply w-[50%];
}

.add-update-user {
  @apply w-[40%];
}

.add-update-img {
  @apply w-[60%];
}

.tryItNow {
  @apply rounded-lg p-5;

  .close-icon {
    @apply flex justify-end;
  }

  .content-wrapper {
    @apply pt-5 pb-7 px-10;
  }

  .logo {
    @apply flex justify-center border-b border-gray-400 pb-5;
  }

  .field-input {
    @apply bg-[#F7F7F7] px-5 py-2 border border-[#CECECE] focus-visible:outline-[#282D35] rounded-lg w-full;
  }

  .form-field-wrapper {
    @apply my-10;
  }

  .submit-btn {
    @apply flex gap-2 justify-center items-center w-full bg-[#282D35] border border-[#282D35] text-white rounded-lg py-2 font-semibold;
  }

  .text-msg {
    @apply flex flex-col gap-5 text-sm font-semibold;
  }

  .invalid-input {
    @apply focus-visible:outline-red-500 border-2 border-red-500;
  }

  .spinner {
    @apply animate-spin;
  }
}

.error-msg {
  @apply text-red-500 text-left mt-1 text-sm;
}
