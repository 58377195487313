#expandable {
  .expandable-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows 200ms;
  }

  .expandable-wrapper.expanded {
    grid-template-rows: 1fr;
  }
  .expandable-content {
    min-height: 0;
  }
}
