.main-content {
  @apply max-w-[1200px] flex-grow m-auto;
}

.main-content-container {
  @apply flex-grow p-5 lg:p-10 items-center;
}

.account-header {
  @apply bg-green-200 p-5;
}

.filter-white {
  filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(166deg)
    brightness(114%) contrast(101%);
}

.filter-grey {
  filter: invert(52%) sepia(0%) saturate(3525%) hue-rotate(73deg)
    brightness(97%) contrast(85%);
}

.filter-light-green {
  filter: invert(59%) sepia(73%) saturate(981%) hue-rotate(114deg)
    brightness(98%) contrast(102%);
}

.filter-warning {
  filter: invert(16%) sepia(100%) saturate(3665%) hue-rotate(346deg)
    brightness(86%) contrast(118%);
}

.cutsheet-fitting {
  @apply border border-gray-500 w-[65px] h-[65px] rounded-md p-[2px] bg-white;
}

.user-label-btn {
  @apply flex justify-center py-1 text-white text-xs rounded-md min-w-[80px];
}

.add-new-btn,
.navigator-btn,
.save-measurement-btn {
  @apply bg-[#282D35] text-white py-2 px-4 rounded-md flex gap-2 items-center cursor-pointer;
}

.start-over-btn {
  @apply bg-red-500 text-white py-2 px-6 rounded-md flex gap-2 items-center cursor-pointer;
}

.add-more-fitting-btn {
  @apply bg-[#282D35] text-white py-2 px-6 rounded-md flex gap-2 items-center cursor-pointer justify-center h-[42px];
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
  @apply px-5 py-2 border border-gray-400 focus-visible:outline-[#000] rounded-md w-full;
}

.submit-btn {
  @apply flex gap-3 items-center justify-center bg-[#282D35] text-white px-8 py-2 rounded-md hover:bg-gray-600;
}

.search-bar {
  @apply cursor-pointer flex mt-8;
  input {
    @apply px-5 bg-[#E9E9E9] border-none focus-visible:outline-none  rounded-br-none rounded-tr-none;
  }

  .search-icon {
    @apply flex items-center justify-between bg-[#282D35] text-white px-5 py-3 rounded-tr-md rounded-br-md;
  }
}

.boxed-icon {
  @apply py-8 border border-gray-500 rounded-md flex flex-col gap-2 justify-center items-center;
}

.try-it-now-btn {
  @apply mt-8 flex gap-3 items-center justify-center bg-[#282D35] text-white px-8 py-3 rounded-md hover:bg-gray-600 max-w-max cursor-pointer;
}

.training-card {
  @apply w-1/3 rounded-xl border border-gray-200 shadow-md;
}

.gray-btn {
  @apply text-white min-w-[7rem] flex justify-center py-2 rounded-md;
}

.green-btn,
.gray-btn,
.red-btn {
  @apply text-white min-w-[7rem] flex justify-center py-2 rounded-md cursor-pointer;
}

.gray-btn {
  @apply bg-[#282D35];
}

.green-btn {
  @apply bg-[#00E09E];
}

.red-btn {
  @apply bg-[#ef4444];
}

.detail-val-status {
  @apply bg-[#282D35] text-xs rounded-md flex justify-center text-white py-1 px-5;
}

table.site-fitting-table {
  width: 100%;
  text-align: left;
}

table.site-fitting-table th {
  padding: 10px 0 10px 0;
}
table.site-fitting-table td {
  padding: 10px 0 10px 0;
  vertical-align: middle;
}
table.site-fitting-table thead th {
  font-weight: normal;
}
table.site-fitting-table tfoot td {
  font-size: 14px;
}
table.site-fitting-table tfoot .links {
  text-align: right;
}
table.site-fitting-table tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

#user-setting-menu {
  @apply absolute bg-[#282D35] px-4 py-3 flex flex-col gap-2 text-white rounded-md z-20 min-w-max top-[50px] right-[5px] text-sm;
}

.sample-ads {
  @apply p-7;

  .ad-icon {
    @apply px-2 py-1 rounded-md  bg-[#282D35] font-bold;
  }

  .ad-title {
    @apply text-xl font-black text-[#282D35];
  }

  .ad-price {
    @apply text-6xl font-extrabold text-[#282D35] mt-5 mb-3;
  }

  .ad-desc {
    @apply bg-[#282D35] text-white py-1 px-3 font-semibold max-w-max rounded-md;
  }
}

.grouped-fittings {
  @apply flex justify-between p-3 bg-[#E8E8E8] rounded-md;

  .edit-btn,
  .delete-btn {
    @apply py-1 px-3 bg-[#F7F7F7] rounded-md border border-gray-300 cursor-pointer;
  }

  .all-valid {
    @apply bg-[#00E09E];
    img {
      @apply bg-[#00E09E];
    }
  }

  .invalid-icon {
    @apply absolute flex h-[30px] w-[30px] text-white justify-center items-center top-[-4px] bg-red-500 rounded-full font-semibold z-50 left-[-3px] cursor-pointer text-sm;
  }
}

table.fitting-uploads {
  width: 100%;
  text-align: left;
}

table.fitting-uploads th {
  padding: 10px 0 10px 0;
}
table.fitting-uploads td {
  padding: 10px 0 10px 0;
  vertical-align: middle;
}
table.fitting-uploads thead th {
  font-weight: normal;
}
table.fitting-uploads tfoot td {
  font-size: 14px;
}
table.fitting-uploads tfoot .links {
  text-align: right;
}
table.fitting-uploads tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

.fittings-search-bar {
  @apply relative cursor-pointer flex items-center;
  input {
    @apply pl-10 pr-3 border-gray-400 focus-visible:outline-none;
  }

  .search-icon {
    @apply absolute flex items-center justify-between px-3 py-3 text-gray-500;
  }
}

// style input checkbox as button
.details-btn {
  @apply select-none rounded-md;

  div {
    @apply text-center py-2 rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 cursor-pointer drop-shadow-md;
  }

  label input {
    @apply hidden;
  }

  input:checked + div {
    @apply bg-[#282D35] text-white text-center;
  }
}

.quote-type {
  @apply bg-[#9EFCFF] px-3 border border-gray-400 rounded-md text-sm;
}

.order-type {
  @apply bg-[#FFDE5C] px-3 border border-gray-400 rounded-md text-sm;
}

.job-type-btn {
  @apply cursor-pointer flex justify-center py-2 w-[80px] border border-gray-400 rounded-md;
}

.valid-quote {
  @apply bg-[#9EFCFF];
  img {
    @apply bg-[#9EFCFF];
  }
}

.invalid-fitting {
  @apply bg-[#ef4444];
  img {
    @apply bg-[#ef4444];
  }
}

.valid-order {
  @apply bg-[#FFDE5C];
  img {
    @apply bg-[#FFDE5C];
  }
}

.disabled-btn {
  @apply opacity-70 pointer-events-none;
}
